export default global.config = {
    // API URL must end to '/'
    api: 'https://api.vira.ai', // Production, please don't use unless really really necessary
    // api: 'https://api.staging.vira.ai', // Staging
    // api: 'https://api.test.vira.ai', // Test environment, this is the default
    // api: 'http://localhost:8022', // Local development, see github.com/gfusion-oy/vira-api for instructions
    // apikey: 'LocalHostTestingKey', // Needed only when domain doesn't match to *.vira.ai
    log: function(msg1, msg2) {
         // console.log(msg1, msg2);
    },
    vira: 'VIRA',
    typeSpeed : 30,
    cursorBlinkSpeed : 350,
    loadingTime : 300,
}
