import {
  Dimensions,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from "react-native";
import { Styles, buttonStyle } from "../../styles/UserChatStyles";
import { useEffect, useRef, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import config from "../../config";
import CustomText from "./CustomText";
import CustomInput from "./CustomInput";
import axios from "axios";

const RegistrationForm = ({ form, color, onSubmitForm }) => {
  const fontColor = color;
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [formDataReg, setFormDataReg] = useState({
    first_name: "",
    last_name: "",
    email: "",
    position_id: "",
    cv: null,
    cover_letter: null,
    profile_picture: null,
  });
  const [cvTitle, setCvTitle] = useState("");
  const [requireCV, setReqCV] = useState(false);
  const [hasCV, setHasCV] = useState(true);
  const [cvError, setCVError] = useState("");

  const [requireCover, setReqCover] = useState(false);
  const [hasCover, setHasCover] = useState(true);
  const [coverTitle, setCoverTitle] = useState("");
  const [coverError, setCoverError] = useState("");

  const [requireProfile, setReqProfile] = useState(false);
  const [hasProfile, setHasProfile] = useState(true);
  const [profileTitle, setProfileTitle] = useState("");
  const [profileError, setProfileError] = useState("");

  const [error, setError] = useState("");
  const [isError, setIsError] = useState(false);
  const screenWidth = Dimensions.get("window").width;
  const fileInputRef = useRef(null);
  const fileInputCVRef = useRef(null);
  const fileInputCoverRef = useRef(null);
  const allowedImageTypes = [
    "image/jpeg",
    "image/png",
    "image/jpg",
    "image/webp",
  ];
  const allowedFileTypes = [
    "text/plain",
    "application/pdf",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.oasis.opendocument.text",
  ];
  const buildValidationSchema = () => {
    const baseSchema = Yup.object().shape({
      first_name: Yup.string().required("First Name is required"),
      last_name: Yup.string().required("Last Name is required"),
      email: Yup.string().email("Invalid email").required("Email is required"),
    });
    return baseSchema;
  };

  useEffect(() => {
    const fieldsData = form.fields.reduce((acc, field) => {
      const { name, value, type } = field;
      acc[name] =
        type === "string"
          ? value || ""
          : type === "hidden"
          ? value || ""
          : type === "file"
          ? null
          : "";
      return acc;
    }, {});

    setFormDataReg((prevData) => ({ ...prevData, ...fieldsData }));
    const setFieldRequirement = (fieldName) =>
      form.fields.some(
        (field) => field.name === fieldName && field.required === "required"
      );
    const hasField = (fieldName) =>
      form.fields.some((field) => field.name === fieldName);
    setHasCV(hasField("cv"));
    setHasCover(hasField("cover_letter"));
    setHasProfile(hasField("profile_picture"));
    setReqProfile(setFieldRequirement("profile_picture"));
    setReqCV(setFieldRequirement("cv"));
    setReqCover(setFieldRequirement("cover_letter"));
  }, [form.fields]);

  const submitRegistrationForm = async (values) => {
    try {
      setSubmitting(true);
      // Basic validation checks
      if (!values.email || !values.first_name || !values.last_name) {
        // Handle validation error
        setSubmitting(false);
        return;
      }

      const headers = {
        "Content-Type": "multipart/form-data",
        ...(config.apikey ? { "Vira-AI-API-Key": config.apikey } : {}),
        Accept: "application/json",
      };

      const formData1 = new FormData();
      formData1.append("email", values.email);
      formData1.append("first_name", values.first_name);
      formData1.append("last_name", values.last_name);
      formData1.append("position_id", formDataReg.position_id);

      if (requireProfile && !formDataReg.profile_picture) {
        if (profileError.length === 0)
          setProfileError("Profile picture is required");
      }
      if (requireCV && !formDataReg.cv) {
        if (cvError.length === 0) setCVError("CV is required");
      }

      if (requireCover && !formDataReg.cover_letter) {
        if (coverError.length === 0) setCoverError("Cover letter is required");
      }

      // Check if any error occurred
      if (
        (requireCV && !formDataReg.cv) ||
        (requireCover && !formDataReg.cover_letter) ||
        (requireProfile && !formDataReg.profile_picture)
      ) {
        setSubmitting(false);
        return;
      }

      if (formDataReg.cv) {
        formData1.append("cv", formDataReg.cv, cvTitle);
      }

      if (formDataReg.cover_letter) {
        formData1.append("cover_letter", formDataReg.cover_letter, coverTitle);
      }

      if (formDataReg.profile_picture) {
        formData1.append("profile_picture", formDataReg.profile_picture);
      }

      const apiUrl = config.api + form.target;
      const response = await axios.post(apiUrl, formData1, { headers });
      const data = await response.data;

      if (response && response.status === 200) {
        setIsSubmitted(true);
        onSubmitForm(true);
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errors) {
        setIsError(true);
        setError(error.response.data.message);
      }
    } finally {
      setSubmitting(false);
    }
  };
  const handleFileUpload = (inputRef) => {
    inputRef.current.click();
  };
  const handleFileChange = (field, event, allowedTypes) => {
    const file = event.target.files[0];

    if (file) {
      if (allowedTypes.includes(file.type)) {
        setFormDataReg((prevData) => ({
          ...prevData,
          [field]: file,
        }));

        if (field === "cv") {
          setCvTitle(file.name);
          setCVError("");
        } else if (field === "profile_picture") {
          setProfileTitle(file.name);
          setProfileError("");
        } else if (field === "cover_letter") {
          setCoverTitle(file.name);
          setCoverError("");
        }
      } else {
        // Invalid file type
        event.target.value = null;
        if (field === "cv") {
          setCVError("invalid file");
          setCvTitle("");
        }
        if (field === "cover_letter") {
          setCoverError("invalid file");
          setCoverTitle("");
        }
        if (field === "profile_picture") {
          setProfileError("invalid file");
          setProfileTitle("");
        }
        setFormDataReg((prevData) => ({
          ...prevData,
          [field]: null,
        }));
      }
    }
  };

  return (
    <Formik
      initialValues={{ ...formDataReg }}
      onSubmit={submitRegistrationForm}
      validationSchema={buildValidationSchema(requireCover)}
      validateOnChange={true}
    >
      {({
        handleChange,
        handleBlur,
        handleSubmit,
        values,
        errors,
        touched,
      }) => (
        <View style={{ width: "100%", display: "flex", gap: 14 }}>
          <CustomText style={{ alignSelf: "stretch", color: fontColor }}>
            After you submit your application below, you will be contacted by
            email for a 1st round interview
          </CustomText>

          <View style={{ display: "flex", gap: 8 }}>
            <CustomText style={[Styles.labelText, { color: fontColor }]}>
              First Name{" "}
              <Text style={buttonStyle.redAsterisk}>* </Text>
            </CustomText>

            <CustomInput
              value={values.first_name}
              onChangeText={handleChange("first_name")}
              onBlur={handleBlur("first_name")}
              editable={!isSubmitted}
              placeholder="First Name"
              placeholderTextColor={fontColor}
              style={[Styles.inputText, { color: fontColor }]}
            />
            {touched.first_name && errors.first_name && (
              <Text
                style={{
                  fontSize: "0.8rem",
                  outlineStyle: "none",
                  paddingBottom: 5,
                  color: "#f14242",
                }}
              >
                {errors.first_name}
              </Text>
            )}
            <Text style={[Styles.labelText, { color: fontColor }]}>
              Last Name{" "}
              <Text style={buttonStyle.redAsterisk}>*</Text>
            </Text>

            <TextInput
              value={values.last_name}
              onChangeText={handleChange("last_name")}
              onBlur={handleBlur("last_name")}
              required
              editable={!isSubmitted}
              placeholder="Last Name"
              placeholderTextColor={fontColor}
              style={[Styles.inputText, { color: fontColor }]}
            />

            {touched.last_name && errors.last_name && (
              <Text
                style={{
                  fontSize: "0.8rem",
                  outlineStyle: "none",
                  paddingBottom: 5,
                  color: "#f14242",
                }}
              >
                {errors.last_name}
              </Text>
            )}
            <CustomText style={[Styles.labelText, { color: fontColor }]}>
              Email <Text style={buttonStyle.redAsterisk}>*</Text>
            </CustomText>

            <TextInput
              onChangeText={handleChange("email")}
              onBlur={handleBlur("email")}
              value={values.email}
              required
              editable={!isSubmitted}
              placeholder="Email"
              placeholderTextColor={fontColor}
              style={[Styles.inputText, { color: fontColor }]}
            />

            {errors.email && touched.email && (
              <Text
                style={{
                  fontSize: "0.8rem",
                  outlineStyle: "none",
                  color: "#f14242",
                }}
              >
                {errors.email}
              </Text>
            )}
          </View>
          <View style={{ display: "flex", gap: 24 }}>
            {hasCV ? (
              <View
                style={[
                  buttonStyle.horizontalRow,
                  { flexDirection: screenWidth < 600 ? "column" : "row" },
                ]}
              >
                <CustomText style={{ color: fontColor }}>
                  Your Resume/CV{" "}
                  {requireCV && <Text style={buttonStyle.redAsterisk}>* </Text>}
                </CustomText>

                <TouchableOpacity
                  style={buttonStyle.uploadButton}
                  onPress={() => handleFileUpload(fileInputCVRef)}
                >
                  <CustomText style={{ color: fontColor }}>Upload</CustomText>
                </TouchableOpacity>

                <input
                  type="file"
                  accept="*/*"
                  style={{ display: "none" }}
                  ref={fileInputCVRef}
                  onChange={(event) =>
                    handleFileChange("cv", event, allowedFileTypes)
                  }
                ></input>

                {cvTitle ? (
                  <CustomText style={{ color: fontColor }}>
                    {cvTitle}
                  </CustomText>
                ) : null}

                {cvError ? (
                  <CustomText style={[Styles.errorText, { marginLeft: 10 }]}>
                    {cvError}
                  </CustomText>
                ) : null}
              </View>
            ) : null}
            {hasProfile ? (
              <View
                style={[
                  buttonStyle.horizontalRow,
                  { flexDirection: screenWidth < 600 ? "column" : "row" },
                ]}
              >
                <CustomText style={{ color: fontColor }}>
                  Profile Picture
                  {requireProfile && (
                    <Text style={buttonStyle.redAsterisk}>*</Text>
                  )}
                  {""}
                </CustomText>
                <TouchableOpacity
                  style={[buttonStyle.uploadButton, { color: fontColor }]}
                  onPress={() => handleFileUpload(fileInputRef)}
                >
                  <CustomText style={{ color: fontColor }}>Upload </CustomText>
                </TouchableOpacity>
                <input
                  type="file"
                  accept="image/*"
                  style={{ display: "none" }}
                  ref={fileInputRef}
                  onChange={(event) =>
                    handleFileChange(
                      "profile_picture",
                      event,
                      allowedImageTypes
                    )
                  }
                ></input>
                {profileTitle ? (
                  <CustomText style={{ color: fontColor }}>
                    {profileTitle}
                  </CustomText>
                ) : null}
                {profileError ? (
                  <CustomText style={[Styles.errorText, { marginLeft: 10 }]}>
                    {profileError}
                  </CustomText>
                ) : null}
              </View>
            ) : null}
            {hasCover ? (
              <View
                style={[
                  buttonStyle.horizontalRow,
                  { flexDirection: screenWidth < 600 ? "column" : "row" },
                ]}
              >
                <CustomText style={{ color: fontColor }}>
                  Your Cover Letter{" "}
                  {requireCover && (
                    <Text style={buttonStyle.redAsterisk}>* </Text>
                  )}
                </CustomText>

                <TouchableOpacity
                  style={buttonStyle.uploadButton}
                  onPress={() => handleFileUpload(fileInputCoverRef)}
                >
                  <CustomText style={{ color: fontColor }}>Upload </CustomText>
                </TouchableOpacity>

                <input
                  type="file"
                  accept="*/*"
                  style={{ display: "none" }}
                  ref={fileInputCoverRef}
                  onChange={(event) =>
                    handleFileChange("cover_letter", event, allowedFileTypes)
                  }
                ></input>

                {coverTitle ? (
                  <CustomText style={{ color: fontColor }}>
                    {coverTitle}
                  </CustomText>
                ) : null}

                {coverError ? (
                  <CustomText style={[Styles.errorText, { marginLeft: 10 }]}>
                    {coverError}
                  </CustomText>
                ) : null}
              </View>
            ) : null}

            {isError && (
              <CustomText style={Styles.errorText}>{error}</CustomText>
            )}
          </View>
          <CustomText
            style={{
              color: fontColor,
            }}
          >
            (By clicking “Submit”, you are agreeing to give us access to your Resume/CV to assess your background and
            compatibility for the process of interviewing you and potentially hiring you. All data you send us is
            encrypted at the database level, and, only those who are involved in the interview process have permission
            to access your info. We will share our full data privacy policy with you in the next interview round.)
          </CustomText>
          <CustomText
              style={{
                color: fontColor,
              }}
          >
            (Instantaneously after clicking ‘Submit’, you will receive an email with your unique URL first round
            interview. If you do not receive it in your inbox, check your junk box!)
          </CustomText>
          <View style={Styles.submitButtonContainer}>
            <TouchableOpacity
              style={buttonStyle.uploadButton}
              disabled={isSubmitted}
              onPress={handleSubmit}
            >
              {isSubmitted ? (
                <CustomText style={{ color: fontColor }}>Submitted</CustomText>
              ) : (
                <CustomText style={{ color: fontColor }}>Submit</CustomText>
              )}
            </TouchableOpacity>
          </View>
          {submitting ? (
            <View
              style={{
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CustomText style={{ color: fontColor, fontSize: 13 }}>
                Submitting...
              </CustomText>
            </View>
          ) : null}
        </View>
      )}
    </Formik>
  );
};

export default RegistrationForm;
